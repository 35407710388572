/**
 * MODULE PURPOSE:
 * To house functions specific to applicant portal vehicle changes.
 */

function validateVehicleChange(action, newVehicle, bookingId, bookingTable, oldVehicle = false) {
	return $.ajax({
		url: '/applicant/validateVehicleChange',
		type: 'POST',
		dataType: 'JSON',
		data: {
			action,
			newVehicle,
			oldVehicle,
			bookingId,
			bookingTable,
		}
	});
}

function vehicleChangeQuestionnaire(bookingId, bookingTable, action) {
	return $.ajax({
		url: '/applicant/getVehicleChangeQuestionnaire',
		type: 'POST',
		dataType: 'HTML',
		data: {
			bookingId,
			bookingTable,
			action
		}
	});
}

function changeVehicle($this, vehicle, bookingId, bookingTable, questionnaire = false) {
	$.ajax({
		url: '/applicant/changeVehicle',
		type: 'POST',
		dataType: 'json',
		data: {
			vehicle,
			bookingId,
			bookingTable,
			questionnaire
		},
		beforeSend: function() {
			$this.prop('disabled', true);
			$('#reveal-change-permit-vehicle select[name="vehicle"]').prop('disabled', true);
		},
		success: function(data) {
			$('#reveal-change-permit-vehicle select[name="vehicle"]').prop('disabled', false);
			if (data.error == true) {
				if (!questionnaire) {
					$this.prop('disabled', false);
					$('#change-permit-vehicle-errors').html(data.errorMsg).fadeIn();
				} else {
					$('#change-vehicle-questionnaire-errors').html(data.errorMsg).fadeIn();
				}
			} else {
				window.location.reload();
			}
		},
		error: function(obj, textStatus, serverErr) {
			if (!questionnaire) {
				$this.prop('disabled', false);
				$('#reveal-change-permit-vehicle select[name="vehicle"]').prop('disabled', false);
				$('#change-permit-vehicle-errors').html('Error: ' + serverErr).fadeIn();
			} else {
				$('#change-vehicle-questionnaire-errors').html(data.errorMsg).fadeIn();
			}
		}
	});
}

function changeMultiVehicle($this, oldVehicle, newVehicle, bookingId, bookingTable, questionnaire = false) {
	$.ajax({
		url: '/applicant/changeMultiVehicle',
		type: 'POST',
		dataType: 'json',
		data: {
			oldVehicle,
			newVehicle,
			bookingId,
			bookingTable,
			questionnaire,
		},
		beforeSend: function() {
			$this.prop('disabled', true);
			$('#change-multi-permit-vehicle select[name="vehicle"], select[name="assignedVehicle"]').prop('disabled', true);
		},
		success: function(data) {
			$('#change-multi-permit-vehicle select[name="vehicle"], select[name="assignedVehicle"]').prop('disabled', false);

			if (data.error == true) {
				$this.prop('disabled', false);
				$('body').find('.change-permit-vehicle-errors').show().removeClass('hide').html(data.errorMsg);
			} else {
				window.location.reload();
			}
		},
		error: function(obj, textStatus, serverErr) {
			$this.prop('disabled', false);
			$('#change-multi-permit-vehicle select[name="vehicle"], select[name="assignedVehicle"]').prop('disabled', false);
			$('#reveal-change-permit-vehicle-multi-errors').html('Error: ' + serverErr).fadeIn();
		}
	});
}

function addVehicle($this, vehicleId, bookingId, bookingTable, questionnaire = false) {
	$.ajax({
		url: '/applicant/addVehicleToMultiPermit',
		type: 'POST',
		dataType: 'json',
		data: {
			vehicleId,
			bookingId,
			bookingTable,
			questionnaire
		},
		beforeSend: function() {
			$this.prop('disabled', true);
			$('#reveal-add-permit-vehicle-multi select[name="vehicle"]').prop('disabled', true);
		},
		success: function(data) {
			$('#reveal-add-permit-vehicle-multi select[name="vehicle"]').prop('disabled', false);

			if (data.error == true) {
				$this.prop('disabled', false);
				$('#reveal-add-permit-vehicle-multi-errors').html(data.errorMsg).fadeIn();
			} else {
				window.location.reload();
			}
		},
		error: function(obj, textStatus, serverErr) {
			$this.prop('disabled', false);
			$('#reveal-add-permit-vehicle-multi select[name="vehicle"]').prop('disabled', false);
			$('#reveal-add-permit-vehicle-multi-errors').html('Error: ' + serverErr).fadeIn();
		}
	});
}

function removeVehicle($this, vehicleId, bookingId, bookingTable) {
	$.ajax({
		url: '/applicant/removeVehicleFromMultiPermit',
		type: 'POST',
		dataType: 'json',
		data: {
			vehicleId,
			bookingId,
			bookingTable
		},
		beforeSend: function() {
			$this.prop('disabled', true);
			$('#reveal-remove-permit-vehicle-multi select[name="assignedVehicle"]').prop('disabled', true);
		},
		success: function(data) {
			$('#reveal-remove-permit-vehicle-multi select[name="assignedVehicle"]').prop('disabled', false);

			if (data.error == true) {
				$this.prop('disabled', false);
				$('body').find('#reveal-remove-permit-vehicle-multi-errors').html(data.errorMsg).show();
			} else {
				window.location.reload();
			}
		},
		error: function(obj, textStatus, serverErr) {
			$this.prop('disabled', false);
			$('#reveal-remove-permit-vehicle-multi select[name="assignedVehicle"]').prop('disabled', false);
			$('#reveal-remove-permit-vehicle-multi-errors').html('Error: ' + serverErr).fadeIn();
		}
	});
}

export {
	changeVehicle,
	changeMultiVehicle,
	addVehicle,
	removeVehicle,
	validateVehicleChange,
	vehicleChangeQuestionnaire
};
